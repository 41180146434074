export default [
  {
    title: 'Home',
    route: 'staff-dashboard',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Dashboard',
  },
  {
    header: 'Novel',
    action: 'manage',
    resource: 'Novel',
  },
  {
    title: 'บันทึกเสี่ยงสูง "รายใหม่"',
    route: 'staff-high-possibility-novel-fill-form',
    icon: 'FileIcon',
    action: 'manage',
    resource: 'Novel',
  },
  {
    title: 'ค้นหาบันทึก',
    route: 'staff-novel-list',
    icon: 'FileIcon',
    action: 'manage',
    resource: 'Novel',
  },
  {
    title: 'สร้างรายการใหม่',
    route: 'staff-novel-full-form-create-new',
    icon: 'FileIcon',
    action: 'manage',
    resource: 'Novel',
  },
  {
    header: 'จัดการเจ้าหน้าที่',
  },
  {
    title: 'เพิ่มเจ้าหน้าที่',
    route: 'staff-create-new',
    icon: 'FileIcon',
    action: 'create',
    resource: 'Staff',
  },
  {
    title: 'เจ้าหน้าที่ทั้งหมด',
    route: 'staff-list',
    icon: 'FileIcon',
    action: 'read',
    resource: 'Staff',
  },
]
